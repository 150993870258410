enum Gender {
	Unknown = "Unknown",
	Male = "Male",
	Female = "Female",
	NonBinary = "Non-binary",
	NotSay = "Prefer not to say"
}

enum CitizenStatus {
	NotSpecified = "NotSpecified",
	Citizen = "Canadian Citizen",
	PermanentResident = "Permanent Resident",
	Immigrant = "Sponsored Immigrant",
	Refugee = "Refugee",
	RefugeeClaimant = "Refugee Claimant",
	Indigenous = "First Nation(s) / Indigenous",
	DeportationOrder = "Deportation Order",
	Other = "Other",
	Metis = "Metis",
	Inuit = "Inuit",
	UkrainianEvacuee = "Ukrainian Evacuee",
}

enum BedroomCount {
	Bachelor = "Bachelor",
	One = "1 bedroom",
	Two = "2 bedroom",
	Three = "3 bedroom",
	FourPlus = "4 or more bedrooms",
	NotSpecified = "Not Specified"
}

const ExtendedBedroomCount = {
	...BedroomCount,
	FourPlus: "4 bedroom",
	FivePlus: "5+ bedrooms"
};

enum LivingSituation {
	coOwn = "Co-Own",
	own = "Own",
	Rent = "Rent",
	WithFamily = "Staying with friend or relative",
	Temporary = "Temporary",
	Homeless = "Homeless"
}

enum YesNo {
	Yes = "Yes",
	No = "No",
}

enum YesNoUnsure {
	Yes = "Yes",
	No = "No",
	Unsure = "Unsure"
}

enum YesNoSilent {
	Yes = "Yes",
	No = "No",
	PreferNotToSay = "Prefer not to say"
}

enum StudentStatus {
	notStudent = "Not a Student",
	partTime = "Part Time",
	fullTime = "Full Time"
}

enum PhoneNumberType {
	mobile = "Mobile",
	home = "Home",
	work = "Work",
}

enum RelationToApplicant {
	friend = "Friend",
	grandparent = "Grandparent",
	other = "Other",
	otherRelative = "Other Relative",
	parent = "Parent",
	sibling = "Sibling",
	spouseOrPartner = "Spouse / Partner",
	child = "Child"
}

enum HouseholdMembersType {
	ADULTS = "adults",
	DEPENDANTS = "dependants",
}

enum ApplicationType {
	Standard = "Standard",
	InterimReview = "InterimReview",
	AnnualReview = "AnnualReview"
}

enum ApplicationStatus {
	Draft = "Draft",
	Generated = "Generated",
	Submitted = "Submitted, Pending Initial Review",
	WaitingForSupportingDocuments = "Awaiting Supporting Documents",
	PendingApproval = "Supporting Documents Received",
	Eligible = "Eligible",
	Approved = "Approved",
	Cancelled = "Cancelled",
	Rejected = "Rejected",
	Expired = "Expired",
	OnHold = "On Hold"
}

export const ReadonlyApplicationStatuses = [
	ApplicationStatus.Rejected,
	ApplicationStatus.Cancelled,
	ApplicationStatus.Approved
];

export const PermissionRequiredFor = (type?: ApplicationType, status?: ApplicationStatus) => {
	const area = type === ApplicationType.InterimReview
		? "interim-review"
		: (type === "AnnualReview" ? "annual-review" : "application");
	
	switch (status) {
		case ApplicationStatus.Eligible: return `${area}.eligible`;
		case ApplicationStatus.Submitted: return `${area}.submitted`;
		case ApplicationStatus.PendingApproval: return `${area}.pending-approval`;
		default: return `${area}.write`;
	}
};

enum OfferState {
	OnOffer = "On Offer",
	Accepted = "Accepted",
	Rescind = "Rescind"
}

enum ClientStatus {
	Active = "Active",
	Inactive = "Inactive",
	Suspended = "Suspended",
}

enum EmailTemplate {
	RequestDocuments = "supporting-documents",
	RejectApplication = "reject-application",
	CancelApplication = "cancel-application",
	CancelInterimReview = "cancel-interimreview",
	SuspendApplication = "suspend-application",
	SubmitApplication = "submit-application",
	ApproveApplication = "approve-application",
	EligibleApplication = "eligible-application",
	OfferApplication = "offer-application",
	DeactivateClient = "deactivate-client",
	SuspendClient = "suspend-client",
	ActivateClient = "activate-client",
	ApproveReview = "approve-review",
	ApproveInterimReview = "approve-interimreview",
	ExtendClient = "extend-client",
	OnNoticeClient = "onnotice-client",
}

enum RateStatus {
	Active = "Active",
	Inactive = "Inactive"
}

enum IncomeTypeStatus {
    Active = "Active",
    Inactive = "Inactive"
}

enum ProgramBudgetStatus {
	Active = "Active",
	Inactive = "Inactive"
}

enum IncomeFrequency {
	Weekly = "Weekly",
	BiWeekly = "Bi-Weekly",
	SemiMonthly = "Semi-Monthly",
	Monthly = "Monthly",
	Quarterly = "Quarterly",
	Annually = "Annually"
}

enum FileStatus {
	Generated = "Generated",
	Approved = "Approved",
	Uploaded = "Uploaded",
	Closed = "Closed",
	Cancelled = "Cancelled",
	Rejected = "Rejected"
}

enum PaymentStatus {
	Failed = "Payment failed",
	Paid = "Payment sent",
	Pending = "Pending",
	Upcoming = "Upcoming",
	Cancelled = "Cancelled",
	OnHold = "On Hold"
}

enum AnnouncementStatus {
	Active = "Active",
	Inactive = "Inactive"
}

enum AnnouncementType {
	Site = "Site",
	Info = "Info",
	Warning = "Warning"
}

enum CommunicationPreference {
	Email = "Email",
	Paper = "Paper"
}

export {
	Gender,
	CitizenStatus,
	BedroomCount,
	ExtendedBedroomCount,
	LivingSituation,
	YesNo,
	YesNoUnsure,
	YesNoSilent,
	StudentStatus,
	PhoneNumberType,
	RelationToApplicant,
	HouseholdMembersType,
	ApplicationType,
	ApplicationStatus,
	ClientStatus,
	EmailTemplate,
	RateStatus,
    IncomeTypeStatus,
	ProgramBudgetStatus,
	IncomeFrequency,
	FileStatus,
	PaymentStatus,
	AnnouncementStatus,
	AnnouncementType,
	OfferState,
	CommunicationPreference
};