import styles from "styles/application.module.scss";
import gridStyles from "styles/gridTable.module.scss";

import {Box, Button, ButtonGroup, Checkbox, FormControlLabel, Grid, TextField, Typography} from "@mui/material";
import {FileInfo, fullWidth, Note, oneThirdWidth, twoThirdWidth} from "library";
import React, { FC, useMemo, useState } from "react";
import {Lock, LockOpen} from "@mui/icons-material";
import {DocumentsCard} from "components/DocumentsCard";
import useAuth from "hooks/useAuth";
import {FormValueDisplay, PpulusLoader} from "components";
import NotesRow from "../views/client/Notes.Row";
import GridTable, { GridTableProps } from "./GridTable";
import { PpulusColumn } from "types/grid";
import { User } from "@frontegg/redux-store";

const NotesList: FC<{ notes: Note[], onChange: (notes: Note[]) => Promise<void>, onDownload: (fileName: string) => void }> = ({notes, onChange, onDownload}) => {
	const {user, canEffect} = useAuth();
	const [editMode, setEditMode] = useState(false);
	const [viewedNote, setViewedNote] = useState<Note>();
	const [processing, setProcessing] = useState(false);

	const isReadonly = useMemo(() => !canEffect("notes.write"), [canEffect]);

	const onView = (note: Note, editMode: boolean) => {
		setViewedNote(note);
		setEditMode(editMode);
	};

	const onSave = async (note: Note) => {
		if (isReadonly) return;

		setProcessing(true);
		note.id
			? await onChange(notes.map(n => n.id !== note?.id ? n : new Note({...note, modifiedBy: user?.name})))
				.then(() => setProcessing(false))
			: await onChange([...notes, new Note({...note, createdBy: user?.name})])
				.then(() => setProcessing(false));
		setEditMode(false);
		setViewedNote(undefined);
	};

	const onDelete = async (note: Note) => {
		if (isReadonly) return;

		setProcessing(true);
		await onChange(notes.filter(n => n.id !== note.id))
			.then(() => setProcessing(false));
	};

	return (
		<>
			{viewedNote
				? <NoteForm value={viewedNote} readonly={isReadonly} editMode={editMode} onCancel={() => setViewedNote(undefined)} onSave={onSave} onDownload={onDownload}/>
				: <NoteGrid user={user} notes={notes} readonly={isReadonly} processing={processing} onNew={() => onView(new Note(), true)} onView={onView} onDelete={onDelete}/>
			}
		</>
	);
};

const NoteGrid: FC<{ user: User | null | undefined, notes: Note[], readonly: boolean, processing: boolean, onNew: () => void, onView: (note: Note, editMode: boolean) => void, onDelete: (note: Note) => Promise<void> }> = ({user, notes, readonly, processing, onNew, onView, onDelete}) => {
	const columns: PpulusColumn<Note>[] = [
		...NotesRow.Columns,
		{
			field: null,
			header: null,
			minSize: 200,
			renderCell: row => [
				<Button variant={"text"} className={gridStyles.compactButton} disabled={processing} onClick={() => onView(row, false)}>View</Button>,
				<Button variant={"text"} className={gridStyles.compactButton} disabled={readonly || processing || row.createdBy !== user?.name} onClick={() => onView(row, true)}>Edit</Button>,
				<Button variant={"text"} className={gridStyles.compactButton} disabled={readonly || processing || row.createdBy !== user?.name}
					onClick={() => window.confirm("Are you sure you want to delete this note?") && onDelete(row)}>Delete</Button>
			]
		}
	];

	const gridTableProps: GridTableProps<Note> = {
		gridDataModel: "client",
		exportCsvEnabled: true,
		datasource: notes,
		count: notes.length,
		initialPageSize: 15,
		page: 0,
		exportFileNamePrefix: "NotesList",
		defaultFilterValue: [],
		columns: columns,
		enableToolbar: false,
		onRowDoubleClick: row => onView(row, false)
	};

	return (
		<>
			<Grid item {...fullWidth} className={styles.buttonGroup}>
				<Typography variant={"h3"}>Notes</Typography>
				<ButtonGroup className={styles.right}>
					{!readonly && <Button disabled={processing} variant={"outlined"} size="small" onClick={onNew}>New Note</Button>}
				</ButtonGroup>
			</Grid>
			<Grid item {...fullWidth}>
				<GridTable {...gridTableProps}/>
			</Grid>
		</>
	);
};

const NoteForm: FC<{ value?: Note, readonly: boolean, editMode: boolean, onCancel: () => void, onSave: (note: Note) => Promise<void>, onDownload: (fileName: string) => void }> = ({value, editMode, readonly, onCancel, onSave, onDownload}) => {
	const [note, setNote] = useState(new Note(value));
	const [processing, setProcessing] = useState(false);

	const set = async (value: Partial<Note>) => {
		setNote(new Note({...note, ...value}));
	};

	const saveNote = () => {
		const validated = note.validate();
		setNote(validated);
		if (Object.values(validated.errorState).some(v => !!v)) return;

		setProcessing(true);
		onSave(validated)
			.then(() => setProcessing(false));
	};

	const isReadOnly = useMemo(() => readonly || processing, [readonly, processing]);

	return (
		<>
			{processing && <PpulusLoader/>}
			<Grid item {...fullWidth} display={"flex"} className={styles.noteRow}>
				<Grid item {...twoThirdWidth}>
					<FormValueDisplay label={""} valueWidth={fullWidth}
						value={<TextField label={"Subject / Title"}
							value={note.subject}
							disabled={!editMode || isReadOnly}
							variant={"outlined"}
							fullWidth
							onChange={e => set({subject: e.target.value})}
						/>}
					/>
				</Grid>
				<Grid item {...oneThirdWidth}>
					<FormValueDisplay label={""} className={styles.flexEnd}
						value={<FormControlLabel label={"Priority Note"}
							labelPlacement={"start"}
							checked={note.isPriority}
							disabled={!editMode || isReadOnly}
							control={<Checkbox onChange={() => set({isPriority: !note.isPriority})}/>}
						/>}
					/>
                                      
					<FormValueDisplay label={""} className={styles.flexEnd}
						value={<FormControlLabel label={note.internal ? "Internal" : "Public"}
							labelPlacement={"start"}
							checked={note.internal}
							disabled={!editMode || isReadOnly}
							control={<Checkbox icon={<LockOpen/>} checkedIcon={<Lock/>} onChange={() => set({internal: !note.internal})}/>}
						/>}
					/>
				</Grid>
			</Grid>
			<Grid item {...fullWidth} className={styles.noteRow}>
				<FormValueDisplay label={""} valueWidth={fullWidth}
					value={<TextField label={"Note"}
						value={note.message}
						disabled={!editMode || isReadOnly}
						error={!!note.errorState.note} helperText={note.errorState.note}
						variant={"outlined"}
						fullWidth multiline rows={4}
						onChange={e => set({message: e.target.value})}/>}
				/>
			</Grid>
			<Grid item {...fullWidth} className={styles.noteRow}>
				<div className={styles.fullWidth}>
					<FormValueDisplay label={""} valueWidth={fullWidth}
						value={<DocumentsCard label={"Note files"}
							emptyLabel={"No note files"}
							files={note.files ?? []}
							readonly={!editMode || isReadOnly}
							onView={(fileName) => onDownload(fileName)}
							onAdd={(files) => set({files: [...note.files, ...files.map(f => new FileInfo(f))]})}
							onDelete={(file) => set({files: note.files.filter(f => f.name !== file.name && f.size !== file.size)})}/>}
					/>
				</div>
			</Grid>
			<ButtonGroup className={`${styles.fullWidth} ${styles.buttonGroup}`}>
				<Box className={styles.right}>
					{editMode
						? <>
							<Button disabled={isReadOnly} variant={"contained"} color={"error"} className={`${styles.button}`} onClick={onCancel}>Cancel</Button>
							<Button disabled={isReadOnly} variant={"contained"} className={styles.button} onClick={saveNote}>Save</Button>
						</>
						: <Button variant={"contained"} className={styles.button} onClick={onCancel}>Return</Button>
					}

				</Box>
			</ButtonGroup>
		</>
	);
};

export {
	NotesList
};