import { CurrencyDisplay, DateDisplay, Payment } from "library";
import { createElement, Fragment } from "react";
import { IconCheck } from "@tabler/icons";
import { PpulusColumn} from "types/grid";

class ClientDisbursementRow {
	public static Columns: PpulusColumn<ClientDisbursementRow>[] = [
		{field: "period", header: "Period",	minSize: 100,	renderCell: row => DateDisplay.PeriodNumeric(row.period), initialSortDirection: "asc"},
		{field: "program", header: "Program", renderCell: row => row.program},
		{field: "amount", header: "Amount", minSize: 120, renderCell: row => CurrencyDisplay(row.amount)},
		{field: "type", header: "Type", renderCell: row => row.type},
		{field: "paymentDate", header: "Payment Date", minSize: 140, renderCell: row => DateDisplay.Standard(row.paymentDate)},
		{field: "status", header: "Status", minSize: 140, renderCell: row => row.status},
		{field: "adjusted", header: "Adjusted", minSize: 100, renderCell: row => row.adjusted ? createElement(IconCheck, {color: "green", size: "17px"}) : createElement(Fragment)},
		{field: "notes", header: "Notes", minSize: 1, renderCell: row => row.notes}
	];

	constructor(readonly id: string,
							readonly period: Date | undefined,
							readonly program: string,
							readonly amount: number,
							readonly status: string,
							readonly paymentDate: Date | undefined,
							readonly type: string,
							readonly adjusted: boolean,
							readonly notes: string) {
	}

	static From(payment: Payment, amount?: number): ClientDisbursementRow {
		return new ClientDisbursementRow(
			payment.id ?? "",
			payment.scheduledDate,
			payment.program,
			amount ?? payment.amount,
			payment.status,
			payment.paymentDate,
			payment.type,
			payment.adjustmentMade,
			payment.note
		);
	}
}
export default ClientDisbursementRow;