class Period {
    constructor(readonly year: number, readonly month: number) {
    }

    static Create(from: Date) {
        return new Period(from.getFullYear(), from.getMonth() + 1);
    }

    public getNext(increment = 1): Period {
        const yearIncrement = Math.floor((this.month + increment) / 13);
        const newMonth = (this.month + increment) % 12;
        return new Period(this.year + yearIncrement, newMonth ? newMonth : 12);
    }

    public getPrevious(decrement = 1): Period {
        const yearDecrement = Math.floor((this.month - decrement - 1) / 12); //extra month removed to handle removing the number of months in the current year (result being 0 goes to -1 to decrement the year
        const newMonth = (this.month - decrement) % 12;
        return new Period(this.year + yearDecrement, newMonth ? Math.abs(newMonth) : 12);
    }

    toJSON() {
        return `${this.year}-${this.month.toString().padStart(2, '0')}`;
    }

    toString() {
        return this.toJSON();
    }
}

export {Period};