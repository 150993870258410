import {GridSortItem} from "@mui/x-data-grid";
import {createSlice} from "@reduxjs/toolkit";
import agent, {FilterOptions} from "api/agent";
import { ApplicationDocuments, Client, ClientStatus, DateDisplay, Document, FileInfo, IDocuments, Note, Payment } from "library";
import {RootState} from "store";
import {createAppAsyncThunk} from "./asyncThunk";
import { PpulusGridFilterModel, PpulusPagination } from "types/grid";

const myClients = "myClients";

const getCurrentOptions = (getState: () => RootState) => {
    const {clients: {filter, page, pageSize, sort}} = getState();
    return {filter, page, pageSize, sort};
};

const getClients = createAppAsyncThunk("clients/load", async (options: FilterOptions) => {
    const forMyClients = options.filter?.items.some(f => f.columnField === myClients);
    const clients = await agent.Clients.list({...options, filter: {...options.filter, items: options.filter?.items.filter(i => i.columnField !== myClients) ?? []}}, forMyClients);
    return {...options, ...clients};
});

const getClient = createAppAsyncThunk("client/get", async (id: string, {getState}) => {
    const {clients: {items}} = getState();
    const client = items.find(i => i.id === id) ?? await agent.Clients.get(id);

    return new Client({
        ...client,
        payments: client.payments.length ? client.payments : await agent.Clients.getDisbursements(id),
        journey: client.journey.entries.length ? client.journey : await agent.Clients.getJourney(client.id)
    });
});

const setClientPage = createAppAsyncThunk("clients/setPage", async (paginationInfo: PpulusPagination, {dispatch, getState}) => {
    const options = {...getCurrentOptions(getState), ...{page: paginationInfo.page, pageSize: paginationInfo.pageSize}};
    await dispatch(getClients(options));
    return options;
});

const setClientSort = createAppAsyncThunk("clients/sort", async (sort: GridSortItem | undefined, {dispatch, getState}) => {
    const options = {...getCurrentOptions(getState), sort};
    await dispatch(getClients(options));
    return options;
});

const setClientFilter = createAppAsyncThunk("clients/filter", async (filter: PpulusGridFilterModel, {dispatch, getState}) => {
    const adjustedFilter: PpulusGridFilterModel = !filter.items.some(f => f.columnField === "programs") ? filter : {
        ...filter,
        join: "p in c.programs",
        items: [
          ...filter.items, 
            {columnField: "p.startDate", operatorValue: "onOrBefore", value: DateDisplay.Standard(new Date())}, 
            {columnField: "p.endDate", operatorValue: "onOrAfter", value: DateDisplay.Standard(new Date())}
        ]
    }
    const options = {...getCurrentOptions(getState), ...{filter: adjustedFilter, page: 0}};
    await dispatch(getClients(options));
    return options;
});

const setClient = createAppAsyncThunk("client/set", async (value: { clientValue: Partial<Client>, message?: string, notes?: Note[], sendEmail?: boolean, refreshPayments?: boolean }, {getState}) => {
    const {client: {item: client}} = getState();

    if (!client) throw new Error("No client to update");

    const updatedClient = new Client({
        ...client,
        ...value.clientValue,
        notes: [...(value.clientValue.notes ?? client.notes), ...(value.notes ?? [])]
    });

    const noteFiles = updatedClient.notes.reduce((a, c) => ([...a, ...c.files.map(f => f.file)]), [] as (File | undefined)[]);
    for (const file of noteFiles.filter(f => !!f))
        await agent.Clients.addFile(updatedClient.id, file!, "notes");

    return new Client({
        ...await agent.Clients.update(updatedClient, value.message, value.sendEmail),
        journey: await agent.Clients.getJourney(client.id),
        payments: value.refreshPayments ? await agent.Clients.getDisbursements(client.id) : client.payments
    });
});

const createPayment = createAppAsyncThunk("client/newPayment", async (payment: Payment, {getState}) => {
    const {client: {item: client}} = getState();
    const newPayment = await agent.Payments.update(payment);

    return new Client({...client!, payments: [...client!.payments, newPayment]});
});

const updatePayment = createAppAsyncThunk("client/updatePayment", async (payment: Payment, {getState}) => {
    const {client: {item: client}} = getState();
    const updatedPayment = await agent.Payments.update(payment);

    return new Client({...client!, payments: client!.payments.map(p => p.id !== updatedPayment.id ? p : updatedPayment)});
});

const deletePayment = createAppAsyncThunk("client/deletePayment", async (payment: Payment, {getState}) => {
    const {client: {item: client}} = getState();
    await agent.Payments.delete(payment);

    return new Client({...client!, payments: client!.payments.filter(p => p.id !== payment.id)});
});

const viewClientFinancialInfo = createAppAsyncThunk("client/financialInfo", async (_, {getState}) => {
    const {client: {item: client}} = getState();
    await agent.Clients.getFinancialInfo(client!.id);
});

const viewOrDownloadClientDocument = createAppAsyncThunk("client/viewOrDownloadDocument", async (value: { fileName: string, documentType: string }, {getState}) => {
    const {client: {item: client}} = getState();
    return await agent.Clients.getFile(client!.id, value.fileName, value.documentType);
});

const addClientDocuments = createAppAsyncThunk("client/addDocuments", async (value: { files: File[], type: keyof IDocuments }, {getState}) => {
    const {client: {item: client}} = getState();

    for (const file of value.files)
        await agent.Clients.addFile(client!.id, file, value.type);

    return await agent.Clients.update(new Client({
        ...client!,
        documents: new ApplicationDocuments({
            ...client!.documents,
            ...{[value.type]: {required: true, files: [...(client!.documents[value.type]?.files ?? []), ...value.files.map(f => new FileInfo({name: f.name, type: f.type, size: f.size}))]}}
        })
    }));
});

const deleteClientDocument = createAppAsyncThunk("client/deleteDocument", async (value: Document, {getState}) => {
    const {client: {item: client}} = getState();
    return await agent.Clients.update(new Client({
        ...client!,
        documents: new ApplicationDocuments({
            ...client!.documents,
            ...{[value.documentType]: {files: client!.documents[value.documentType].files.filter(f => !f.equivalentTo(value.file))}}
        })
    }));
});

const clientStatusNoteMapping: Record<ClientStatus, (value: { message: string, notes: string, subject?: string, revisedEndDate?: Date }) => Note[]> = {
    [ClientStatus.Active]: a => [new Note({message: a.notes, subject: a.subject ?? "Client Activated"})],
    [ClientStatus.Inactive]: a => [new Note({message: a.notes, subject: "Client Deactivated"})],
    [ClientStatus.Suspended]: a => [new Note({message: a.notes, subject: "Client Suspended"})]
};

const changeClientStatus = createAppAsyncThunk("client/setStatus", async (value: { deactivationReason: string, status: ClientStatus, message: string, sendEmail: boolean, notes: string, revisedEndDate?: Date }, {getState, dispatch}) => {
    const { client: { item: existing } } = getState();
    if (!existing) return;

    const subject = existing.status === ClientStatus.Active
        ? undefined
        : existing.status === ClientStatus.Inactive ? "Client Re-activated" : "Client Un-suspended";

    const updatedActiveProgram = existing.activeProgram && value.revisedEndDate ? {...existing.activeProgram, endDate: value.revisedEndDate} : undefined;    
    const clientValue = {
        deactivatedReason: value.deactivationReason,
        notes: [
            ...(existing?.notes ?? []),
            ...clientStatusNoteMapping[value.status]({ message: value.message, notes: value.notes, subject, revisedEndDate: value.revisedEndDate })
        ],
        status: value.status,
        programs: updatedActiveProgram ? [...existing.programs.filter(p => !p.isActive), updatedActiveProgram] : existing.programs
    };
    return dispatch(setClient({clientValue, message: value.message, sendEmail: value.sendEmail, refreshPayments: true}))
        .unwrap()
        .then();
});

const client = createSlice({
    name: "client",
    initialState: {loading: false, item: undefined as Client | undefined},
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(getClient.pending, () => ({loading: true, item: undefined}));
        builder.addCase(getClient.fulfilled, (_, {payload}) => ({loading: false, item: payload}));
        builder.addCase(getClient.rejected, (_, action) => console.error("rejected", action));
        builder.addCase(setClient.pending, (state) => ({...state, loading: true}));
        builder.addCase(setClient.fulfilled, (state, {payload}) => ({loading: false, item: new Client({...state.item, ...payload})}));
        builder.addCase(updatePayment.pending, (state) => ({...state, loading: true}));
        builder.addCase(updatePayment.fulfilled, (state, {payload}) => ({...state, loading: false, item: payload}));
        builder.addCase(createPayment.pending, (state) => ({...state, loading: true}));
        builder.addCase(createPayment.fulfilled, (state, {payload}) => ({...state, loading: false, item: payload}));
        builder.addCase(deletePayment.pending, (state) => ({...state, loading: true}));
        builder.addCase(deletePayment.fulfilled, (state, {payload}) => ({...state, loading: false, item: payload}));
        builder.addCase(addClientDocuments.fulfilled, (state, {payload}) => ({...state, item: new Client({...state.item, ...payload})}));
        builder.addCase(deleteClientDocument.fulfilled, (state, {payload}) => ({...state, loading: false, item: payload}));
    }
});

const initialClients: FilterOptions & { loading: boolean, items: Client[], count: number } = {
    page: 0,
    pageSize: 15,
    loading: true,
    items: new Array<Client>(),
    sort: {field: "lastName", sort: "asc"},
    filter: undefined,
    count: 0
};

const clients = createSlice({
    name: "clients",
    initialState: initialClients,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(setClientFilter.fulfilled, (state, {payload}) => ({...state, ...payload}));
        builder.addCase(getClients.pending, (state) => ({...state, loading: true, items: []}));
        builder.addCase(getClients.fulfilled, (state, {payload}) => ({...state, loading: false, ...payload}));
    }
});

export {
    addClientDocuments, changeClientStatus, deleteClientDocument, getClient, getClients, myClients, setClient, setClientFilter, setClientPage,
    setClientSort, updatePayment, createPayment, deletePayment,
    viewClientFinancialInfo,
    viewOrDownloadClientDocument
};
export const clientReducer = client.reducer;
export const clientsReducer = clients.reducer;