import {createSlice} from "@reduxjs/toolkit";
import agent, {FilterOptions} from "api/agent";
import {Application} from "library";
import {GridFilterModel, GridSortItem} from "@mui/x-data-grid";
import {RootState} from "store";
import {createAppAsyncThunk} from "./asyncThunk";
import { PpulusPagination } from "../../types/grid";

const myApplications = "myApplications";

const getCurrentOptions = (getState: () => RootState) => {
	const {applications: {filter, page, pageSize, sort}} = getState();
	return {filter, page, pageSize, sort};
};

const setApplicationPage = createAppAsyncThunk("applications/setPage", async (pageInfo: PpulusPagination, {dispatch, getState}) => {
	const options = {...getCurrentOptions(getState), ...{page: pageInfo.page, pageSize: pageInfo.pageSize}};
	await dispatch(getApplications(options));
	return options;
});

const setApplicationSort = createAppAsyncThunk("applications/sort", async (sort: GridSortItem | undefined, {dispatch, getState}) => {
	const existingOptions = getCurrentOptions(getState);
	if (existingOptions.sort?.field === sort?.field && existingOptions.sort?.sort === sort?.sort)
		return existingOptions;

	return await dispatch(getApplications({...existingOptions, sort})).unwrap().then();
});

const setApplicationFilter = createAppAsyncThunk("applications/filter", async (filter: GridFilterModel, {dispatch, getState}) => {
	const options = {...getCurrentOptions(getState), ...{filter: filter, page: 0}};
	await dispatch(getApplications(options));
	return options;
});

const getApplications = createAppAsyncThunk("applications/load", async (options: FilterOptions) => {
	const forMyApplications = options.filter?.items.some(f => f.columnField === myApplications);
	const fromApi = await agent.Applications.list({...options, filter: {...options.filter, items: options.filter?.items.filter(i => i.columnField !== myApplications) ?? []}}, forMyApplications);
	return {...options, items: fromApi.applications, count: fromApi.count};
});

const generateAnnualReview = createAppAsyncThunk("applications/generateAnnualReview", async (clientCode: string) => await agent.AnnualReview.Create(clientCode));
const generateInterimReview = createAppAsyncThunk("applications/generateInterimReview", async (clientCode: string) => await agent.InterimReview.Create(clientCode));

const initialState: FilterOptions & { loading: boolean, items: Application[], count: number } = {
	loading: true,
	page: 0,
	pageSize: 15,
	sort: {field: "pointScore", sort: "desc"},
	filter: undefined,
	items: [],
	count: 0
};

const applications = createSlice({
	name: "allApplications",
	initialState: initialState,
	reducers: {
	},
	extraReducers: builder => {
		builder.addCase(setApplicationFilter.fulfilled, (state, {payload}) => ({...state, ...payload}))
		builder.addCase(getApplications.pending, (state, {meta}) => ({...state, ...meta.arg, loading: true, items: []}));
		builder.addCase(getApplications.fulfilled, (state, {payload}) => ({...state, loading: false, ...payload}));
		builder.addCase(generateAnnualReview.pending, (state) => ({...state, loading: true}));
		builder.addCase(generateAnnualReview.fulfilled, (state) => ({...state, loading: false}));
		builder.addCase(generateInterimReview.pending, (state) => ({...state, loading: true}));
		builder.addCase(generateInterimReview.fulfilled, (state) => ({...state, loading: false}));
	}
});

export default applications.reducer;

export {getApplications, setApplicationPage, setApplicationSort, setApplicationFilter, generateAnnualReview, generateInterimReview, myApplications};