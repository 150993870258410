import styles from "styles/miscStyles.module.scss";

import { PpulusColumn } from "types/grid";
import { DateDisplay, Note } from "library";

class NotesRow {
	public static Columns: PpulusColumn<Note>[] = [
		{field: "createdOn", header: "Date", size: 180, initialSortDirection: "desc", renderCell: row => DateDisplay.ShortTime(row.createdOn)},
		{field: "subject", header: "Title / Subject", size: 170, renderCell: row => <span className={styles.overflowText}>{row.subject}</span>},
		{field: "message", header: "Note", size: 350, renderCell: row => <span className={styles.overflowText}>{row.message}</span>},
		{field: "createdBy", header: "Created By", size: 130, renderCell: row => row.createdBy},
		{field: "internal", header: "Visibility", size: 100, renderCell: row => row.internal ? "Internal" : "Public"},
		{field: "isPriority", header: "Priority?", size: 100, renderCell: row => row.isPriority ? "Yes" : "No"}
	];
}

export default NotesRow;
