import {RootState} from "../index";
import {createAppAsyncThunk} from "./asyncThunk";
import {GridFilterModel, GridSortItem} from "@mui/x-data-grid";
import {downloadFileBlob, isEmpty, PaymentFile, PaymentFileDocType} from "library";
import agent, {FilterOptions} from "api/agent";
import {createSlice} from "@reduxjs/toolkit";

const getCurrentOptions = (getState: () => RootState) => {
    const {paymentFiles: {filter, page, pageSize, sort}} = getState();
    return {filter, page, pageSize, sort};
};

const setPaymentFilesPage = createAppAsyncThunk("paymentFiles/setPage", async (page: number, {dispatch, getState}) => {
    const options = {...getCurrentOptions(getState), page};
    await dispatch(getPaymentFiles(options));
    return options;
});

const setPaymentFilesSort = createAppAsyncThunk("paymentFiles/sort", async (sort: GridSortItem | undefined, {dispatch, getState}) => {
    const options = {...getCurrentOptions(getState), sort};
    await dispatch(getPaymentFiles(options));
    return options;
});

const setPaymentFilesFilter = createAppAsyncThunk("paymentFiles/filter", async (filter: GridFilterModel, {dispatch, getState}) => {
    const options = {...getCurrentOptions(getState), ...{filter: isEmpty(filter?.items) ? initialState.filter : filter}, page: 0};
    await dispatch(getPaymentFiles(options));
    return options;
});

const getPaymentFiles = createAppAsyncThunk("paymentFiles/load", async (options: FilterOptions, {getState}) => {
    const listOptions = {...getCurrentOptions(getState), ...options};
    const fromApi = await agent.PaymentFiles.list(listOptions);

    return {...listOptions, items: fromApi.paymentsFiles, count: fromApi.count};
});

const downloadPaymentFile = createAppAsyncThunk("paymentFiles/download", async (id: string) => {
    const file = await agent.PaymentFiles.getFile(id);
    downloadFileBlob(file.fileBlob, file.fileName);
});

const addDoc = createAppAsyncThunk("paymentFiles/addDoc", async (value: { id: string, file: File, docType: PaymentFileDocType }, {getState}) => {
    await agent.PaymentFiles.addDoc(value.id, value.file, value.docType);
});

const removeDoc = createAppAsyncThunk("paymentFiles/removeDoc", async (value: { id: string, fileName: string, docType: PaymentFileDocType }) => {
    await agent.PaymentFiles.removeDoc(value.id, value.fileName, value.docType);
});

const updatePaymentFile = createAppAsyncThunk("paymentFiles/update", async (file: PaymentFile) => {
    await agent.PaymentFiles.update(file);
});

const viewOrDownloadFile = createAppAsyncThunk("paymentFiles/downloadDoc", async (value: { id: string, fileName: string, docType: PaymentFileDocType }) => {
    return await agent.PaymentFiles.downloadDoc(value.id, value.fileName, value.docType);
});

const initialFilter = {
    items: []
};

const initialState: FilterOptions & { loading: boolean, items: PaymentFile[], count: number } = {
    loading: true,
    page: 0,
    pageSize: 15,
    sort: {field: "period", sort: "desc"},
    filter: initialFilter,
    items: [],
    count: 0
};

const paymentFiles = createSlice({
    name: "paymentFiles",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getPaymentFiles.pending, (state) => ({...state, loading: true, items: []}));
        builder.addCase(getPaymentFiles.fulfilled, (state, {payload}) => ({...state, loading: false, ...payload}));
        builder.addCase(setPaymentFilesPage.fulfilled, (state, {payload}) => ({...state, ...payload}));
        builder.addCase(setPaymentFilesSort.fulfilled, (state, {payload}) => ({...state, ...payload}));
        builder.addCase(setPaymentFilesFilter.fulfilled, (state, {payload}) => ({...state, ...payload}));
    }
});

export default paymentFiles.reducer;
export {initialFilter, setPaymentFilesSort, setPaymentFilesPage, setPaymentFilesFilter, getPaymentFiles, downloadPaymentFile, addDoc, removeDoc, updatePaymentFile, viewOrDownloadFile};