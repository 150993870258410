// material-ui
import { Alert, AlertTitle, Avatar, Box, ClickAwayListener, Fade, Paper, Popper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';
import LogoSection from '../LogoSection';
import LocalizationSection from './LocalizationSection';
import MegaMenuSection from './MegaMenuSection';
import MobileSection from './MobileSection';
import NotificationSection from './NotificationSection';
import ProfileSection from './ProfileSection';

import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';

// assets
import { IconMenu2 } from '@tabler/icons';
import SearchSection from './SearchSection';
import React, { useState } from 'react';

import { useNavigate } from "react-router-dom";

import agent from "api/agent"
import { Application, Client } from 'library';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const applicationCodeRegex = /^(p\d{7})|((SA)?\d{2}-\w{8})$/i;
const recipientCodeRegex = /^(t\d{7}|\d{2}-\w{8})$/;
const interimReviewCodeRegex = /^IR\d{2}-\w{8}$/i;
const annualReviewCodeRegex = /^AN\d{2}-\w{8}$/i;

const Header = () => {
    const theme = useTheme();

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const { layout } = useConfig();

    const [searchValue, setSearchValue] = useState('');

    const [searchErrorAnchorEl, setSearchErrorAnchorEl] = useState<null | HTMLElement>(null);
    const [showSearchError, setShowSearchError] = useState<boolean>(false);
    const [searchErrorText, setSearchErrorText] = useState<string>('');

    const navigate = useNavigate();

    function handleCodeRedirect<T extends Application | Client>(
        code: string,
        searchErrorValue: string,
        redirectUrl: string,
        currentTarget: EventTarget & HTMLElement,
        get: (id: string) => Promise<T>
    ) {
        get(code)
            .then((res: T) => {
                navigate(`${redirectUrl}/${res.id}`);
            })
            .catch((e) => {
                setShowSearchError(true);
                setSearchErrorText(searchErrorValue);
                setSearchErrorAnchorEl(currentTarget);
            });
    }

    const onEnter = (e: React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLElement, MouseEvent>) => {
        setShowSearchError(false);
        setSearchErrorText('');

        if (searchValue.length <= 0) {
            return;
        }
        else if (searchValue.length < 4) {
            setSearchErrorText('You must enter at least 4 characters');
            setSearchErrorAnchorEl(e.currentTarget.parentElement);
            setShowSearchError(true);
        }
        else if (applicationCodeRegex.test(searchValue)) {
            handleCodeRedirect(searchValue, 'Application not found', '/pages/applications', e.currentTarget, agent.Applications.get);
        }
        else if (recipientCodeRegex.test(searchValue)) {
            handleCodeRedirect(searchValue, 'Recipient not found', '/pages/clients', e.currentTarget, agent.Clients.get);
        }
        else if (interimReviewCodeRegex.test(searchValue)) {
            handleCodeRedirect(searchValue, 'Interim not found', '/pages/interim-reviews', e.currentTarget, agent.Applications.get);
        }
        else if (annualReviewCodeRegex.test(searchValue)) {
            handleCodeRedirect(searchValue, 'Annual not found', '/pages/annual-reviews', e.currentTarget, agent.Applications.get);
        }
        else {
            let queryUrl = `/pages/global-search?searchValue=${searchValue}`;
            navigate(queryUrl);
        }
    }

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd)) && (
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            overflow: 'hidden',
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                            color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                                color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                            }
                        }}
                        onClick={() => dispatch(openDrawer(!drawerOpen))}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="20px" />
                    </Avatar>
                )}
            </Box>

            <Box sx={{ flexGrow: 1 }} style={{ paddingLeft: '20px' }}>
                <SearchSection value={searchValue}
                    setValue={setSearchValue}
                    onEnter={onEnter}
                    showSearchError={showSearchError} />
                <Popper
                    open={showSearchError}
                    anchorEl={searchErrorAnchorEl}
                    placement='bottom'
                    disablePortal
                >
                    <Fade in={showSearchError}>
                        <Paper sx={{ boxShadow: 4 }}>
                            <ClickAwayListener onClickAway={() => setShowSearchError(false)}>
                                <Alert severity='error'>
                                    <AlertTitle>Error</AlertTitle>
                                    {searchErrorText}
                                </Alert>
                            </ClickAwayListener>
                        </Paper>
                    </Fade>
                </Popper>
            </Box>

            {/* header search */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* mega-menu */}
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <MegaMenuSection />
            </Box>

            {/* live customization & localization */}
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <LocalizationSection />
            </Box>

            {/* notification & profile */}
            <NotificationSection />
            <ProfileSection />

            {/* mobile header */}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box>
        </>
    );
};

export default Header;
