import styles from "styles/application.module.scss";

import { Application, ApplicationStatus, ApplicationType, fullWidth, OfferState } from "library";
import { Button, Grid } from "@mui/material";
import { IconArrowBackUp, IconBan, IconCheck, IconCircleX, IconFileCheck, IconGift } from "@tabler/icons";

type ApplicationActionsProps = {
    application: Application;
    onStatusChange: (newStatus: ApplicationStatus | OfferState) => void;
}

const SpecialActions = (application: Application, onStatusChange: (newStatus: ApplicationStatus | OfferState) => void): Partial<Record<ApplicationType, JSX.Element>> => ({
    [ApplicationType.Standard]: <StandardActions application={application} onStatusChange={onStatusChange}/>,
    [ApplicationType.InterimReview]: <InterimReviewActions status={application.status} onStatusChange={onStatusChange}/>,
    [ApplicationType.AnnualReview]: <AnnualReviewActions status={application.status} onStatusChange={onStatusChange}/>
});

const ApplicationActions = ({application, onStatusChange}: ApplicationActionsProps) => {
    const {type, status, canProcess} = application;

    return (
        <Grid item {...fullWidth} className={`${styles.buttonGroup} ${styles.rowTopPadding}`}>
            <div className={styles.left}>
                <Button color={"error"} variant={"contained"} startIcon={<IconBan/>} className={styles.button} onClick={() => onStatusChange(ApplicationStatus.Rejected)}>Reject</Button>
                <Button color={"warning"} variant={"contained"} startIcon={<IconCircleX/>} className={styles.button} onClick={() => onStatusChange(ApplicationStatus.Cancelled)}>Cancel</Button>
            </div>
            {status !== ApplicationStatus.Approved && canProcess && SpecialActions(application, onStatusChange)![type]}
        </Grid>
    );
};

const StandardActions = ({application, onStatusChange}: { application: Application, onStatusChange: (newStatus: ApplicationStatus | OfferState) => void }) => {
    const {status, isOnOffer, isOfferAccepted} = application;

    return (
        <div className={styles.right}>
            {[ApplicationStatus.Draft].includes(status) &&
                <Button color={"primary"} variant={"contained"} startIcon={<IconCheck/>} className={styles.button} onClick={() => onStatusChange(ApplicationStatus.Submitted)}>
                    Submit
                </Button>
            }
            {[ApplicationStatus.Submitted, ApplicationStatus.WaitingForSupportingDocuments, ApplicationStatus.PendingApproval, ApplicationStatus.Eligible].includes(status) && !application.isOnOffer &&
                <Button color={"primary"} variant={"contained"} startIcon={<IconFileCheck/>} className={styles.button} onClick={() => onStatusChange(ApplicationStatus.WaitingForSupportingDocuments)}>
                    Request Documents
                </Button>}
            {[ApplicationStatus.Submitted, ApplicationStatus.WaitingForSupportingDocuments, ApplicationStatus.PendingApproval].includes(status) &&
                <Button color={"primary"} variant={"contained"} startIcon={<IconCheck/>} className={styles.button} onClick={() => onStatusChange(ApplicationStatus.Eligible)}>
                    Eligible
                </Button>}
            {status === ApplicationStatus.Eligible && <>
                {isOfferAccepted 
                    ? <Button color={"primary"} variant={"contained"} startIcon={<IconCheck />} className={styles.button} onClick={() => onStatusChange(ApplicationStatus.Approved)}>Approve</Button>
                    : isOnOffer
                        ? <>
                            <Button color={"primary"} variant={"contained"} startIcon={<IconArrowBackUp />} className={styles.button} onClick={() => onStatusChange(OfferState.Rescind)}>Rescind Offer</Button>
                            <Button color={"primary"} variant={"contained"} startIcon={<IconCheck />} className={styles.button} onClick={() => onStatusChange(OfferState.Accepted)}>Accept Offer</Button>
                        </>
                        : <Button color={"primary"} variant={"contained"} startIcon={<IconGift />} className={styles.button} onClick={() => onStatusChange(OfferState.OnOffer)}>Offer</Button>
                }</>}
        </div>
    );
}

const InterimReviewActions = ({status, onStatusChange}: { status: ApplicationStatus, onStatusChange: (newStatus: ApplicationStatus) => void }) => {
    const isInactive = [ApplicationStatus.Cancelled, ApplicationStatus.Rejected, ApplicationStatus.Approved].includes(status);
    
    return (
        <div className={styles.right}>
            {!isInactive && <>
                <Button color={"primary"} variant={"contained"} startIcon={<IconCheck />} className={styles.button} onClick={() => onStatusChange(ApplicationStatus.WaitingForSupportingDocuments)}>
                    Information Request
                </Button>
                <Button color={"primary"} variant={"contained"} startIcon={<IconCheck />} className={styles.button} onClick={() => onStatusChange(ApplicationStatus.Approved)}>
                    Approve
                </Button>
            </>}
        </div>
    );
}

const AnnualReviewActions = ({status, onStatusChange}: { status: ApplicationStatus, onStatusChange: (newStatus: ApplicationStatus) => void }) => {
    const isInactive = [ApplicationStatus.Cancelled, ApplicationStatus.Rejected, ApplicationStatus.Approved].includes(status);
    
    return (
        <div className={styles.right}>
            {!isInactive && <>
                { [ApplicationStatus.Draft, ApplicationStatus.Generated].includes(status) &&
                  <Button color={"primary"} variant={"contained"} startIcon={<IconCheck />} className={styles.button} onClick={() => onStatusChange(ApplicationStatus.Submitted)}>
                      Submit
                  </Button>
                }
                <Button color={"primary"} variant={"contained"} startIcon={<IconCheck />} className={styles.button} onClick={() => onStatusChange(ApplicationStatus.WaitingForSupportingDocuments)}>
                    Information Request
                </Button>
                <Button color={"primary"} variant={"contained"} startIcon={<IconCheck />} className={styles.button} onClick={() => onStatusChange(ApplicationStatus.Approved)}>
                    Approve
                </Button>
            </>}
        </div>
    );
}

export {
    ApplicationActions
}